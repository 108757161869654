import Vue from 'vue'
import App from './App.vue'
// 关闭vue的生产提示
Vue.config.productionTip = false
import '../src/static/css/quanJu.css'
// 引入路由
import router from './router/index.js'
// 引入路由权限
import './router/permission.js'
// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入echarts

// 引入VUEX
import store from './store'


// 引入vue的滚动 v-scroll-to
import {scroller} from '@/scroll/scrollTo.js'
 
//  引入Echarts
import * as echarts from 'echarts'
// 引入图片懒加载
import VueLazyload from 'vue-lazyload';


// 阿里适量库
import './assets/iconfont/iconfont.css';


// 引入anmites.css
import 'animate.css';


/* 引入swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// 引入横向滚动
// import scroll from 'vue-seamless-scroll';
// 引入 加载loading动画
// import loading from '../src/utils/loading.js';
// 引入滑动插件
import SlideVerify from 'vue-monoplasty-slide-verify';


// 解决弹窗页面抖动
ElementUI.Dialog.props.lockScroll.default = false;
// Vue.use(loading);
Vue.use(SlideVerify);
Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
// Vue.use(scroll);
// Vue.use(VueLazyload, {
 
//     preLoad: 1.3,// 预加载高度的比例
 
//     error: 'dist/error.png', // 加载失败时显示的图片
 
//     loading: 'dist/loading.gif',// 加载中时显示的图片
 
//     attempt: 1// 尝试加载次数
 
// })
Vue.prototype.$scrollTo = scroller()
Vue.prototype.$echarts = echarts
// 全局方法挂载
new Vue({
  el:'#app',
  render: h => h(App),
  router,
  store
})
