// 路由权限
import Vue from 'vue';
import router from './index';
import stort from '../store/index.js';
import {_isMobile} from '../utils/_isMobile.js';
import ProgressBar from '@/view/ProgressBar.vue';//进度条效果
// 创建进度条实例
const progressBar = new Vue(ProgressBar).$mount()
document.body.appendChild(progressBar.$el)


router.beforeEach((to,from,next) => {
	progressBar.start() // 显示进度条
	// if (_isMobile() === null) {
	// 	// web端
	// 	if (to.fullPath.indexOf('index/profession/detail.html?') !== -1) {
	// 		let url = to.fullPath;
	// 		url = url.split('?')
	// 		next('/professionDetails?' + url[1])
	// 		// if (_isMobile() === null) {
				
	// 		// }else{
	// 		// 	window.location.href='https://m.haopaiwang.net/pages/lots/detail?' + url[1]
	// 		// }
	// 	}else{
	// 		if (!stort.state.user.token) {
				
	// 			// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
	// 			if (to.meta.isLogin === true) {
					
	// 				next('/login')
	// 			}else{
	// 				window.scrollTo(0, 0);//跳转页面后直接到顶部
	// 				next()
	// 			}
	// 		}else{
	// 			// 用户已经登录  放行
	// 			if (to.path === '/login') {
	// 				if (stort.state.user.token === '') {
	// 					next('/login');
	// 				}else{
	// 					next('/user/account');
	// 				}
					 
	// 			}else{
	// 				next()
	// 			}
				
	// 		}
	// 	}
	// }else{
	// 	// 移动端
	// 	let url = to.fullPath;
	// 	if (url === '/') {
	// 		// 跳转app首页
	// 		window.location.href = 'https://m.haopaiwang.net/'
	// 	}else if (url.indexOf('/professionDetails?') !== -1) {
	// 		// 跳转详情
	// 		let params = url.split('?')[1]
	// 		window.location.href = 'https://m.haopaiwang.net/pages/lots/detail?' + params
	// 	}else{
	// 		if (!stort.state.user.token) {
				
	// 			// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
	// 			if (to.meta.isLogin === true) {
					
	// 				next('/login')
	// 			}else{
	// 				window.scrollTo(0, 0);//跳转页面后直接到顶部
	// 				next()
	// 			}
	// 		}else{
	// 			// 用户已经登录  放行
	// 			if (to.path === '/login') {
	// 				if (stort.state.user.token === '') {
	// 					next('/login');
	// 				}else{
	// 					next('/user/account');
	// 				}
					 
	// 			}else{
	// 				next()
	// 			}
				
	// 		}
	// 	}
		
	// }
	
	
	if (!stort.state.user.token) {
		
		// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
		if (to.meta.isLogin === true) {
			
			next('/login')
		}else{
			window.scrollTo(0, 0);//跳转页面后直接到顶部
			next()
		}
	}else{
		// 用户已经登录  放行
		if (to.path === '/login') {
			if (stort.state.user.token === '') {
				next('/login');
			}else{
				next('/user/account');
			}
			 
		}else{
			next()
		}
		
	}
	
	
})
router.afterEach((to, from) => {
 window.scrollTo(0, 0);
 
 
 progressBar.finish() // 隐藏进度条
})

