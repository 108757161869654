<template>
	<div id="header" v-if="$route.path === '/login' || $route.path === '/forget' || $route.path === '/register' || $route.path === '/'? false:true ">
		<div class="title">
			<div class="title_left">您好,欢迎来到鑫好拍网</div>
			<div class="title_center">
				<img src="../static/image/title2.png" alt="" >
			</div>
			<div class="title_right">
				
				<div class="title_right_title" @click="Help()">帮助中心</div>
				<div class="title_right_title" @click="goLogin" v-if="tokens === ''">立即登录</div>
				<div class="title_right_title" @click="goMy" v-if="tokens !== ''">个人中心</div>
				<div class="title_right_title" @click="onSubmit" v-if="tokens !== ''">退出登录</div>
				<div class="title_right_title" @click="GoToOldPMS()">拍卖师登录</div>
				<div class="title_right_title" @click="abouyt('/about')">关于我们</div>
			<!-- 	<div class="title_right_title" @click="GoToOld()">旧版入口</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import {mapMutations,mapState} from 'vuex'
export default {
	data () {
		return{
			tokens:'',
		}
	},
	created() {
		this.tokens = this.$store.state.user.token
		
	},
	computed:{
		...mapState('user',['token'])
	},
	watch:{
		token:{
			deep:true ,//深度监听
			handler(newValue,oldValue){
				this.tokens = newValue;
			}
		}
	},
	methods:{
		...mapMutations('user',['SETUSER','REMOVEUSER']),
		// 立即登录
		goLogin() {
			// this.$router.push({
			// 	path:'/login'
			// })
			//  this.$router.replace({
			//       path:'/login',
			//       query: {
			//       	redirect: this.$router.currentRoute.fullPath // router对象自带的属性
			//       }
			// })
			this.$router.push({
			      path:'/login',
			      query: {
			      	redirect: this.$router.currentRoute.fullPath // router对象自带的属性
			      }
			})
		},
		// 退出登录*********************************
		onSubmit() {
			// localStorage.removeItem('info')
			localStorage.removeItem('info');
			localStorage.removeItem('idUser');
			this.REMOVEUSER()
			this.$router.push({
				path:'/'
			})
		},
		// 关于我们*******************************
		abouyt(url){
			open(url,'_blank')
		},
		// 帮助中心登录******************************
		Help(){
			this.$router.push({
				path:'/Help'
			})
		},
		// 个人中心
		goMy(){
			this.$router.push({
				path:'/user'
			})
		},
		// 跳转旧版本
		GoToOld(){
			let url = 'https://past.haopaiwang.net/';
			open(url,'_blank')
		},
		// 拍卖师登录
		GoToOldPMS() {
			let url = 'https://past.haopaiwang.net/auctioneer/login.html';
			open(url,'_blank')
		}
	}
}
</script>

<style scoped lang="scss">
#header{
	min-width: 1500px;
	height: 35px;
	background-color: #f6f6f6;
	.title{
		width: 1200px;
		height: 100%;
		background-color: #f6f6f6;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		.title_left{
			width: 160px;
			height: 35px;
			font-size: 12px;
			font-weight: 400;
			color: #999;
			line-height: 35px;
			margin-left: 20px;
		}
		.title_right{
			height: 35px;
			display: flex;
			align-items: center;
			.title_right_title{
				padding-right: 6px;
				box-sizing: border-box;
				border-right: 1px solid #ddd;
				margin-right: 6px;
				font-weight: 400;
				color: #999;
				line-height: 24px;
				&:hover{
					cursor: pointer;
					color: #000000;
				}
			}
		}
		.title_center{
			height: 35px;
			width: 380px;
			display: flex;
			align-items: center;
		}
	}
}
</style>
