// 统一配置文件
import store from '../store/index.js'
export default {
	// 白名单
	whiteListApi: [
		//不要验证token的 
		'/', '/bid', '/auction', '/price', '/enter', '/deal', '/qiyesub', '/forget', '/targetmulu',
		'/auctiondetails',
		'/notice', '/noticeDetail', '/news', '/about', '/404', '/cuowu', '/tradePlatform'
	],
	// 配置秘钥
	secretId: 'helloWord', //可以是后端告诉我
	// 接口随机时间
	rand: Math.random(),
	// 图片地址前部分
	url: 'https://paimai-1304461808.file.myqcloud.com/',
	// 首页接口的分页
	// homeLimit: 6,
	// // 首页接口的页码
	// homePage: 1,
	// // 首页接口的type
	// homeType:2
	// 页码
	page: 1,
	// 分页
	limit: 12,
	// 上传图片地址
	uploadUrl: 'https://api.haopaiwang.net/api/upload',
	headers: {
		Authorization: "Bearer " + store.state.user.token,
	},
	userId: "", //监听多页面只能登录一个账户
	resZt: [{
			id: 9999,
			isActive: true,
			title: '全部'
		},
		{
			id: 10000001,
			isActive: false,
			title: '废金属'
		},
		{
			id: 10000002,
			isActive: false,
			title: '周转材料'
		},
		{
			id: 10000003,
			isActive: false,
			title: '机械设备'
		},
		{
			id: 10000004,
			isActive: false,
			title: '活动房屋'
		},
		{
			id: 10000005,
			isActive: false,
			title: '机动车辆'
		},
		{
			id: 10000006,
			isActive: false,
			title: '电子电器'
		},
		{
			id: 10000007,
			isActive: false,
			title: '电线电缆'
		},
		{
			id: 10000008,
			isActive: false,
			title: '办公用品'
		},
		{
			id: 10000009,
			isActive: false,
			title: '小型机具'
		},
		{
			id: 10000010,
			isActive: false,
			title: '库存物资'
		},
		{
			id: 10000011,
			isActive: false,
			title: '其他'
		}
	],
	ztwt: [{
			id: 9999,
			isActive: true,
			name: '全部'
		},
		{
			id: 0,
			isActive: false,
			name: '竞价'
		},
		{
			id: 1,
			isActive: false,
			name: '询价'
		}
	],
	// 中铁省份
	zteconomize: [{
			code: 99999999999,
			isActive: true,
			name: '全部'
		},
		{
			code: 'A01811',
			isActive: false,
			name: '北京市'
		},
		{
			code: 'A01812',
			isActive: false,
			name: '天津市'
		},
		{
			code: 'A01813',
			isActive: false,
			name: '河北省'
		},
		{
			code: 'A01814',
			isActive: false,
			name: '山西省'
		},
		{
			code: 'A01815',
			isActive: false,
			name: '内蒙古自治区'
		},
		{
			code: 'A01821',
			isActive: false,
			name: '辽宁省'
		},
		{
			code: 'A01822',
			isActive: false,
			name: '吉林省'
		},
		{
			code: 'A01823',
			isActive: false,
			name: '黑龙江省'
		},
		{
			code: 'A01831',
			isActive: false,
			name: '上海市'
		},
		{
			code: 'A01832',
			isActive: false,
			name: '江苏省'
		},
		{
			code: 'A01833',
			isActive: false,
			name: '浙江省'
		},
		{
			code: 'A01834',
			isActive: false,
			name: '安徽省'
		},
		{
			code: 'A01835',
			isActive: false,
			name: '福建省'
		},
		{
			code: 'A01836',
			isActive: false,
			name: '江西省'
		},
		{
			code: 'A01837',
			isActive: false,
			name: '山东省'
		},
		{
			code: 'A01841',
			isActive: false,
			name: '河南省'
		},
		{
			code: 'A01842',
			isActive: false,
			name: '湖北省'
		},
		{
			code: 'A01843',
			isActive: false,
			name: '湖南省'
		},
		{
			code: 'A01844',
			isActive: false,
			name: '广东省'
		},
		{
			code: 'A01845',
			isActive: false,
			name: '广西壮族自治区'
		},
		{
			code: 'A01846',
			isActive: false,
			name: '海南省'
		},
		{
			code: 'A01850',
			isActive: false,
			name: '重庆市'
		},
		{
			code: 'A01851',
			isActive: false,
			name: '四川省'
		},
		{
			code: 'A01852',
			isActive: false,
			name: '贵州省'
		},
		{
			code: 'A01853',
			isActive: false,
			name: '云南省'
		},
		{
			code: 'A01854',
			isActive: false,
			name: '西藏自治区'
		},
		{
			code: 'A01861',
			isActive: false,
			name: '陕西省'
		},
		{
			code: 'A01862',
			isActive: false,
			name: '甘肃省'
		},
		{
			code: 'A01863',
			isActive: false,
			name: '青海省'
		},
		{
			code: 'A01864',
			isActive: false,
			name: '宁夏回族自治区'
		},
		{
			code: 'A01865',
			isActive: false,
			name: '新疆维吾尔族自治区'
		},
		{
			code: 'A01871',
			isActive: false,
			name: '台湾省'
		},
		{
			code: 'A01881',
			isActive: false,
			name: '香港特别行政区'
		},
		{
			code: 'A01882',
			isActive: false,
			name: '澳门特别行政区'
		}
	],
	dajiaUnit: [{
			remark: "",
			label: "批",
			id: 203,
			value: "0"
		},
		{
			remark: "",
			label: "辆",
			id: 204,
			value: "1"
		},
		{
			remark: "",
			label: "吨",
			id: 205,
			value: "2"
		},
		{
			remark: "",
			label: "套",
			id: 387,
			value: "3"
		},
		{
			remark: "",
			label: "-",
			id: 430,
			value: "5"
		},
		{
			remark: "",
			label: "台",
			id: 431,
			value: "6"
		},
		{
			remark: "",
			label: "米",
			id: 434,
			value: "7"
		},
		{
			remark: "",
			label: "kg",
			id: 435,
			value: "8"
		},
		{
			remark: "",
			label: "㎡",
			id: 436,
			value: "9"
		},
		{
			remark: "",
			label: "个",
			id: 437,
			value: "10"
		},
		{
			remark: "",
			label: "件",
			id: 438,
			value: "11"
		},
		{
			remark: "",
			label: "公斤",
			id: 440,
			value: "13"
		},
		{
			remark: "",
			label: "克",
			id: 439,
			value: "12"
		},
		{
			remark: "",
			label: "具",
			id: 441,
			value: "14"
		},
		{
			remark: "",
			label: "包",
			id: 442,
			value: "15"
		},
		{
			remark: "",
			label: "张",
			id: 447,
			value: "16"
		},
		{
			remark: "",
			label: "升",
			id: 444,
			value: "17"
		},
		{
			remark: "",
			label: "只",
			id: 445,
			value: "18"
		},
		{
			remark: "",
			label: "块",
			id: 446,
			value: "19"
		},
		{
			remark: "",
			label: "斤",
			id: 449,
			value: "20"
		},
		{
			remark: "",
			label: "支",
			id: 448,
			value: "21"
		},
		{
			remark: "",
			label: "条",
			id: 450,
			value: "22"
		},
		{
			remark: "",
			label: "架",
			id: 451,
			value: "23"
		},
		{
			remark: "",
			label: "栋",
			id: 452,
			value: "24"
		},
		{
			remark: "",
			label: "根",
			id: 453,
			value: "25"
		},
		{
			remark: "",
			label: "桶",
			id: 454,
			value: "26"
		},
		{
			remark: "",
			label: "次",
			id: 455,
			value: "27"
		},
		{
			remark: "",
			label: "片",
			id: 456,
			value: "28"
		},
		{
			remark: "",
			label: "环",
			id: 457,
			value: "29"
		},
		{
			remark: "",
			label: "瓶",
			id: 458,
			value: "30"
		},
		{
			remark: "",
			label: "盏",
			id: 459,
			value: "31"
		},
		{
			remark: "",
			label: "m³",
			id: 460,
			value: "32"
		},
		{
			remark: "",
			label: "箱",
			id: 461,
			value: "33"
		},
		{
			remark: "",
			label: "m",
			id: 462,
			value: "34"
		},
		{
			remark: "",
			label: "组",
			id: 463,
			value: "35"
		},
		{
			remark: "",
			label: "艘",
			id: 464,
			value: "36"
		},
		{
			remark: "",
			label: "节",
			id: 465,
			value: "37"
		},
		{
			remark: "",
			label: "部",
			id: 466,
			value: "38"
		},
		{
			remark: "",
			label: "间",
			id: 467,
			value: "39"
		},
		{
			remark: "",
			label: "年",
			id: 468,
			value: "40"
		},
		{
			remark: "",
			label: "棵",
			id: 469,
			value: "41"
		},
		{
			remark: "",
			label: "项",
			id: 470,
			value: "42"
		},
		{
			remark: "",
			label: "t",
			id: 1596,
			value: "43"
		},
		{
			remark: "",
			label: "T",
			id: 1597,
			value: "44"
		},
		{
			remark: "",
			label: "顿",
			id: 1598,
			value: "45"
		},
		{
			remark: "",
			label: "平方米",
			id: 1599,
			value: "46"
		},
		{
			remark: "",
			label: "平方",
			id: 1600,
			value: "47"
		},
		{
			remark: "",
			label: "平米",
			id: 1601,
			value: "48"
		},
		{
			remark: "",
			label: "立方米",
			id: 1602,
			value: "49"
		},
		{
			remark: "",
			label: "千克",
			id: 1603,
			value: "50"
		},
		{
			remark: "",
			label: "台套",
			id: 1604,
			value: "51"
		},
		{
			remark: "",
			label: "一批",
			id: 1605,
			value: "52"
		},
		{
			remark: "",
			label: "批次",
			id: 1606,
			value: "53"
		},
		{
			remark: "",
			label: "盘",
			id: 1607,
			value: "54"
		},
		{
			remark: "",
			label: "堆",
			id: 1608,
			value: "55"
		}
	]
}