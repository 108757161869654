import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from './modules/user';
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)


const store = new Vuex.Store({
	
	modules:{
		user
	},
	getters,
	// plugins:[] vuex插件 数组写法 多个插件名称
	plugins:[
		createPersistedstate({
			//storage:window.sessionStorage,//存储类型，默认localStorage
			key:'info',//存储vuex数据的任意健民--本地储存里面
			paths:['user'],//存储的模块名称一级全局state数据 不写默认储存所有内容
		})
	]
})


export default store